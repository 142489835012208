import { cloneDeep } from 'lodash-es';

import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { ControlGroup } from '@domain/models/ControlGroup';
import {
  ControlGroupSessionForm,
  InputGroupSessionForm,
  ObjectiveConfigParams
} from '@domain/models/createExperiment/ObjectiveConfigParams';
import { NewExperimentObjectiveDto } from '@domain/models/experiment/NewExperimentObjectiveDto';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { NewExperimentDto } from '@domain/models/experiment/NewExperimentDto';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { ExperimentFormFormatter } from '@app/mappers/experiment/ExperimentFormFormatter';
import { RegionDto } from '@domain/models/RegionDto';
import { ObjectiveMapper } from '@app/mappers/experiment/ObjectiveMapper';
import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { RegionMapper } from '@app/mappers/experiment/RegionMapper';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { BasicInfoParams } from '@domain/models/createExperiment/BasicInfoParams';
import { ExperimentFormState } from '@infrastructure/store/createExperiment/createExperimentReducer';
import { RegionOption, TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';
import { GoalConfigParams } from '@domain/models/createExperiment/GoalConfigParams';
import { DateConfigParams } from '@domain/models/createExperiment/DateConfigParams';
import { AdvancedConfigParams } from '@domain/models/createExperiment/AdvancedConfigParams';
import { ExperimentRegion, ExperimentRegionName } from '@domain/enums/ExperimentRegion';

export class ExperimentFormMapper {
  static mapDefaultInputGroups(
    variables: ExperimentVariableDto[],
    controlGroup?: ControlGroup
  ): InputGroupSessionForm[] {
    const defaultGroup = { sessionIndex: '1' };

    // 1. Define default group based on experiment variable
    variables.forEach(({ name, defaultDisplayValue, type }) => {
      defaultGroup[name] = ExperimentFormFormatter.formatDefaultInputGroupValue(defaultDisplayValue, type);
    });

    // 2. In case of absent control group return result based on experiment variables
    if (!controlGroup) {
      return [defaultGroup];
    }

    const clone = cloneDeep(defaultGroup);

    // 3. Define init state of input groups
    const result = [clone];

    // 4. Update data based on received control group
    Object.keys(controlGroup).forEach((param) => {
      const variable = variables.find(({ name }) => name === param);

      if (!variable) {
        return;
      }

      if (variable.sessionsSupported) {
        Object.keys(controlGroup[param]).forEach((sessionIndex) => {
          // find index of control group with particular session index
          const resultIndex = result.map((item) => item.sessionIndex).indexOf(sessionIndex);

          // insert new control group if we don't have control group for this session index
          if (resultIndex === -1) {
            result.push({ ...cloneDeep(defaultGroup), sessionIndex });
          }

          // find index again, because it could update due to insert new control group
          const updateResultIndex = result.map((item) => item.sessionIndex).indexOf(sessionIndex);
          result[updateResultIndex][param] = ExperimentFormFormatter.formatInputGroupValue(
            controlGroup[param][sessionIndex],
            variable.type
          );
        });
        return;
      }

      result[0][param] = ExperimentFormFormatter.formatInputGroupValue(controlGroup[param], variable.type);
    });

    return result;
  }

  static mapDefaultControlGroups(
    variables: ExperimentVariableDto[],
    controlGroup?: ControlGroup
  ): ControlGroupSessionForm[] {
    const defaultGroup = { sessionIndex: '1' };

    // 1. Define default group based on experiment variable
    variables.forEach(({ name, defaultDisplayValue, type }) => {
      defaultGroup[name] = ExperimentFormFormatter.formatDefaultControlGroupValue(defaultDisplayValue, type);
    });

    // 2. In case of absent control group return result based on experiment variables
    if (!controlGroup) {
      return [defaultGroup];
    }

    const clone = cloneDeep(defaultGroup);

    // 3. Define init state of control groups
    const result = [clone];

    // 4. Update data based on received control group
    Object.keys(controlGroup).forEach((param) => {
      const variable = variables.find(({ name }) => name === param);

      if (!variable) {
        return;
      }

      if (variable.sessionsSupported) {
        Object.keys(controlGroup[param]).forEach((sessionIndex) => {
          // find index of control group with particular session index
          const resultIndex = result.map((item) => item.sessionIndex).indexOf(sessionIndex);

          // insert new control group if we don't have control group for this session index
          if (resultIndex === -1) {
            result.push({ ...cloneDeep(defaultGroup), sessionIndex });
          }
          // find index again, because it could update due to insert new control group
          const updatedResultIndex = result.map((item) => item.sessionIndex).indexOf(sessionIndex);

          result[updatedResultIndex][param] = ExperimentFormFormatter.formatControlGroupValue(
            controlGroup[param][sessionIndex],
            variable.type
          );
        });
        return;
      }

      result[0][param] = ExperimentFormFormatter.formatControlGroupValue(controlGroup[param], variable.type);
    });

    return result;
  }

  // todo add test
  static mapObjectiveFormToValidateConfigPayload(
    form: ObjectiveConfigParams,
    variables: ExperimentVariableDto[],
    experimentType: ExperimentType
  ): NewExperimentObjectiveDto {
    const payload = new NewExperimentObjectiveDto();

    payload.experimentType = experimentType;
    payload.builtInGroup = form.builtInGroup;
    payload.adjustableUsersAllocation = form.adjustableUsersAllocation;
    payload.newUsers = form.newUsers;
    payload.usersAllocationPercent = Number(form.usersAllocationPercent);
    payload.cloneControlGroup = form.cloneControlGroup;
    payload.controlGroup = ObjectiveMapper.mapControlGroup(form, variables);
    payload.input = ObjectiveMapper.mapInput(form, variables);

    return payload;
  }

  // todo add test
  static mapObjectiveParamsToNewExperimentObjective(
    form: Record<string, ObjectiveConfigParams>,
    configList: Record<string, GenericConfigEntry[]>,
    variables: ExperimentVariableDto[],
    regions: RegionDto[],
    versions: string[]
  ): NewExperimentObjectiveDto[] {
    return regions.map((region) => {
      const objective = form[region.name];

      const dto = new NewExperimentObjectiveDto();

      dto.configs = configList[region.name];
      dto.controlGroup = ObjectiveMapper.mapControlGroup(objective, variables);
      dto.newUsers = objective.newUsers;
      dto.sticky = objective.sticky;
      dto.usersAllocationPercent = Number(objective.usersAllocationPercent);
      dto.adjustableUsersAllocation = objective.adjustableUsersAllocation;
      dto.input = ObjectiveMapper.mapInput(objective, variables);
      dto.cloneControlGroup = objective.cloneControlGroup;
      dto.builtInGroup = objective.builtInGroup;
      dto.primaryRegion = region.id;
      dto.regions = [region.id];
      dto.appVersions = versions;

      return dto;
    });
  }

  // todo add test
  static mapGLDConfigParamsToNewExperimentObjective(
    form: GLDConfigParams,
    regions: RegionDto[],
    regionForInDev: RegionDto[],
    primaryRegion: RegionDto,
    description: string,
    versions: string[]
  ): NewExperimentObjectiveDto[] {
    const dto = new NewExperimentObjectiveDto();

    const configs = ObjectiveMapper.mapGLDConfigs(form.config);
    const controlGroup = configs[0].entry;

    dto.configs = configs;
    dto.controlGroup = controlGroup;
    dto.primaryRegion = primaryRegion.id;
    dto.regions = regions.map(({ id }) => id);
    dto.regionsForInDev = regionForInDev.map(({ id }) => id);
    dto.newUsers = form.newUsers;
    dto.sticky = form.sticky;
    dto.usersAllocationPercent = Number(form.usersAllocationPercent);
    dto.adjustableUsersAllocation = form.adjustableUsersAllocation;
    dto.cloneControlGroup = form.cloneControlGroup;
    dto.description = description;
    dto.appVersions = versions;

    return [dto];
  }

  // todo add test
  static mapFormToNewExperiment(
    form: ExperimentFormState,
    configList: Record<string, GenericConfigEntry[]>,
    defaultRegions: RegionDto[],
    variables: ExperimentVariableDto[]
  ): NewExperimentDto {
    const { basicInfo, targetConfig, objectiveABConfig, objectiveGLDConfig, goalConfig, dateConfig, advancedConfig } =
      form;
    const isGLD = basicInfo.experimentType === ExperimentType.GLD_TEST;

    const payload = new NewExperimentDto();

    const regions = RegionMapper.getRegionsByNames(
      defaultRegions,
      targetConfig.regions.map(({ value }) => value)
    );
    const regionsForInDev = RegionMapper.getRegionsByNames(
      defaultRegions,
      targetConfig.regionsForInDev.map(({ value }) => value)
    );
    const [primaryRegion] = RegionMapper.getRegionsByNames(defaultRegions, [targetConfig.primaryRegion]);

    const versions = ExperimentFormFormatter.inputArrayToStringArray(targetConfig.versions);

    let objectives;

    if (isGLD) {
      objectives = ExperimentFormMapper.mapGLDConfigParamsToNewExperimentObjective(
        objectiveGLDConfig,
        regions,
        regionsForInDev,
        primaryRegion,
        basicInfo.description,
        versions
      );
    } else {
      objectives = ExperimentFormMapper.mapObjectiveParamsToNewExperimentObjective(
        objectiveABConfig,
        configList,
        variables,
        regions,
        versions
      );
    }

    payload.arpu = Number(goalConfig.arpu);
    payload.checkPeriod = goalConfig.checkPeriod;
    payload.deadline = dateConfig.deadline.getTime();
    payload.experimentName = basicInfo.experimentName;
    payload.experimentObjectives = objectives;
    payload.gameId = basicInfo.gameId;
    payload.minInstallDate = dateConfig.minInstallDate.getTime();
    payload.queryTemplate = advancedConfig.queryTemplate;
    payload.reportingArpu = ExperimentFormFormatter.inputArrayToNumberArray(goalConfig.reportingArpu);
    payload.automaticMode = goalConfig.automaticMode;
    payload.startDateTime = dateConfig.startDateTime.getTime();
    payload.type = basicInfo.experimentType;
    payload.appVersions = versions;
    payload.recommendedProfile = basicInfo.isRecommendedProfile;

    return payload;
  }

  static mapExperimentDtoToBasicInfoParams(experiment: ExperimentDto): BasicInfoParams {
    const params = BasicInfoParams.ofInitial();

    params.experimentType = experiment.experimentType;
    params.experimentName = experiment.experimentName;
    params.gameName = experiment.gameApp.gameName;
    params.platform = experiment.gameApp.platform;
    params.firebaseProjectId = experiment.gameApp.firebaseProjectId;
    params.bundleId = experiment.gameApp.bundleId;
    params.gameId = experiment.gameApp.id;

    return params;
  }

  static mapExperimentDtoToTargetConfigParams(experiment: ExperimentDto): TargetConfigParams {
    const { experimentType, experimentObjectives } = experiment;
    const isGLD = experimentType === ExperimentType.GLD_TEST;
    const params = TargetConfigParams.ofInitial();
    const [objective] = experimentObjectives;

    params.primaryRegion = objective.primaryRegion.name;
    params.versions = objective.appVersions.map((value) => ({ value }));

    if (isGLD) {
      params.regionsForInDev = RegionMapper.createOptions(objective.regionsForInDev);
      params.regions = RegionMapper.createOptions(objective.regions);
    } else {
      let regionType = ExperimentRegion.US;
      let regions: RegionOption[] = [{ value: ExperimentRegionName.US, label: ExperimentRegionName.US }];

      if (experimentObjectives.length > 1) {
        regionType = ExperimentRegion.WW;
        regions = RegionMapper.createOptions(experimentObjectives.map(({ primaryRegion }) => primaryRegion));
      }

      params.regionType = regionType;
      params.regions = regions;
    }

    return params;
  }

  static mapExperimentDtoToGoalConfig(experiment: ExperimentDto): GoalConfigParams {
    const params = GoalConfigParams.ofInitial();

    params.automaticMode = experiment.automaticMode;
    params.arpu = experiment.arpu;
    params.reportingArpu = experiment.reportingArpu.map((value) => ({ value: value.toString() }));
    params.checkPeriod = experiment.checkPeriod;

    return params;
  }

  static mapExperimentDtoToAdvancedConfig(experiment: ExperimentDto): AdvancedConfigParams {
    const params = AdvancedConfigParams.ofInitial();

    return params;
  }

  static mapExperimentDtoToForm(experiment: ExperimentDto): ExperimentFormState {
    return {
      [CreateExperimentForm.BASIC_INFO]: ExperimentFormMapper.mapExperimentDtoToBasicInfoParams(experiment),
      [CreateExperimentForm.TARGET_CONFIG]: ExperimentFormMapper.mapExperimentDtoToTargetConfigParams(experiment),
      [CreateExperimentForm.AB_OBJECTIVE_CONFIG]: ObjectiveMapper.mapExpDtoToABObjectiveConfig(experiment),
      [CreateExperimentForm.GLD_OBJECTIVE_CONFIG]: ObjectiveMapper.mapExpDtoToGLDObjectiveConfig(experiment),
      [CreateExperimentForm.GOAL_CONFIG]: ExperimentFormMapper.mapExperimentDtoToGoalConfig(experiment),
      [CreateExperimentForm.DATE_CONFIG]: DateConfigParams.ofInitial(), // clone of dates is not relevant
      [CreateExperimentForm.ADVANCED_CONFIG]: ExperimentFormMapper.mapExperimentDtoToAdvancedConfig(experiment)
    };
  }
}
